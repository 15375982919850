export enum ViewType {
  ASSETS_VIEW = 'assetView',
  EVENT_VIEW = 'eventView'
}

export enum SeverityIcon {
  CRITICAL = 'stop',
  MINOR = 'warning',
  NORMAL = 'circle'
}

export enum FeatureKeys {
  SPEEDING_CHART_ASSET_SUMMARY = 'speeding-event-over-time-in-asset-summary',
  SPEEDING_CHART_EVENT_VIEW = 'speeding-event-over-time-in-event-view',
  ASSET_PROPERTIES_FILTER = 'asset-properties-filter',
  DRIVER_ASSOCIATION_EVENT_VIEW = 'driver-association'
}

export enum DriverInfoSource {
  PRE_TRIP = 'PRE-TRIP-INSPECTION',
  EVIR_MOBILE = 'EVIR-MOBILE-INSPECTION',
  OPERATOR_ID = 'OPERATOR_ID',
  TABLET = 'TABLET_LOGIN',
  EXTERNAL = 'EXTERNAL',
  NOT_AVAIL = 'NOT_AVAIL'
}

export enum DriverLabel {
  PRE_TRIP = 'preTrip',
  EVIR_MOBILE = 'evirMobile',
  OPERATOR_ID = 'operator',
  TABLET = 'tablet',
  EXTERNAL = 'external',
  NOT_AVAIL = 'notAvail'
}

export enum DISTANCE_UNITS {
  IMPERIAL = 'imperial',
  METRIC = 'metric'
}

export enum LOCALES {
  ENGLISH = 'en',
  ENGLISH_CA = 'en-CA',
  ENGLISH_GB = 'en-GB',
  ENGLISH_US = 'en-US',
  FRENCH = 'fr',
  FRENCH_CA = 'fr-CA',
  FRENCH_FRANCE = 'fr-FR',
  GERMAN = 'de',
  GERMAN_GERMANY = 'de-DE',
  ITALIAN = 'it',
  ITALIAN_ITALY = 'it-IT',
  SPANISH = 'es',
  SPANISH_MX = 'es-MX',
  SPANISH_SPAIN = 'es-ES'
}

export const UNIT_MAPPING = {
  [LOCALES.ENGLISH]: {
    distanceUnits: DISTANCE_UNITS.IMPERIAL
  },
  [LOCALES.ENGLISH_CA]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.ENGLISH_GB]: {
    distanceUnits: DISTANCE_UNITS.IMPERIAL
  },
  [LOCALES.ENGLISH_US]: {
    distanceUnits: DISTANCE_UNITS.IMPERIAL
  },
  [LOCALES.FRENCH]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.FRENCH_CA]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.FRENCH_FRANCE]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.GERMAN]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.GERMAN_GERMANY]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.ITALIAN]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.ITALIAN_ITALY]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.SPANISH]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.SPANISH_MX]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  },
  [LOCALES.SPANISH_SPAIN]: {
    distanceUnits: DISTANCE_UNITS.METRIC
  }
};
